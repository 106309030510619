import { createWebHistory, createRouter } from "vue-router";
import Body from '../components/body';



// comingsoon
import ComingsoonImage from '../pages/comingsoon/comingsoon_image';
import ComingsoonSimple from '../pages/comingsoon/comingsoon_simple';

// error
import Error400 from '../pages/error/error400';
import Error401 from '../pages/error/error401';
import Error403 from '../pages/error/error403';
import Error404 from '../pages/error/error404';
import Error500 from '../pages/error/error500';
import Error503 from '../pages/error/error503';

import login from '../pages/auth/login.vue'
import register from '../pages/auth/register.vue'
import events from '../pages/running/events.vue'
import events_detail from '../pages/running/event_detail.vue'
import events_regis from '../pages/running/event_register.vue'
import events_regis_edit from '../pages/running/event_register_edit.vue'
import events_regis_direct from '../pages/running/event_register_direct.vue'
import eventqr from '../pages/running/eventqr.vue'
import order from '../pages/running/order.vue'
import findOrder from '../pages/running/findOrder.vue';
import about from '../pages/running/about.vue';
import terms_of_use from '../pages/running/terms_of_use.vue';


import dashboard_runner from '../pages/running/dashboard_runner.vue'
import dashboard_organizer from '../pages/running/dashboard_organizer.vue'
import dashboard_organizer_report from '../pages/running/dashboard_organizer_report.vue'
import dashboard_organizer_checkbib from '../pages/running/dashboard_organizer_checkbib.vue'

import dashboard_admin from '../pages/running/dashboard_admin.vue'
import dashboard_admin_report from '../pages/running/dashboard_admin_report.vue'
import profile from '../pages/running/user_profile.vue'

const routes = [
  {
    path: '/',
    component: Body,

    children: [
      {
        path: 'auth/login',
        name: 'login',
        component: login,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
      {
        path: 'auth/register',
        name: 'register',
        component: register,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
      {
        path: 'dashboard',
        name: 'dashboard_runner',
        component: dashboard_runner,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
      {
        path: 'dashboard-organizer',
        name: 'dashboard_organizer',
        component: dashboard_organizer,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
      {
        path: 'dashboard-organizer/:event_key',
        name: 'dashboard_organizer_report',
        component: dashboard_organizer_report,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
      {
        path: 'checkbib-organizer/:event_key',
        name: 'dashboard_organizer_checkbib',
        component: dashboard_organizer_checkbib,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
      {
        path: 'dashboard-admin',
        name: 'dashboard_admin',
        component: dashboard_admin,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
      {
        path: 'dashboard-admin/:event_key',
        name: 'dashboard_admin_report',
        component: dashboard_admin_report,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
      {
        path: 'profile',
        name: 'profile',
        component: profile,
        meta: {
          title: 'profile ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
      {
        path: '',
        name: 'events',
        component: events,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      }, {
        path: ':event_id',
        name: 'events_detail',
        component: events_detail,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      }, {
        path: ':event_key/register',
        name: 'events_regis',
        component: events_regis,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },{
        path: '/order-update/:event_key/:uuid',
        name: 'events_regis_edit',
        component: events_regis_edit,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },{
        path: ':event_key/registerdd',
        name: 'events_regis_direct',
        component: events_regis_direct,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
       {
        path: ':event_key/qr',
        name: 'eventqr',
        component: eventqr,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },{
        path: '/order/:q',
        name: 'order',
        component: order,
        meta: {
          title: 'ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      }, 
      {
        path: '/about',
        name: 'about',
        component: about,
        meta: {
          title: 'เกี่ยวกับเรา-ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      }, 
      {
        path: '/find-order',
        component: findOrder,
        name: 'findOrder',
        meta: {
          title: 'ค้นหาคำสั่งซื้อ-ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
      
      {
        path: '/terms-of-use',
        name: 'terms-of-use',
        component: terms_of_use,
        meta: {
          title: 'ข้อตกลงการใช้บริการ และ นโยบายความเป็นส่วนตัว-ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },
      {
        path: '/404',
        name: '404',
        component: Error404,
        meta: {
          title: 'Page not found | ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH',
        }
      },

    ]
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router