<template>
  <div class="row">&nbsp;</div>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="row">
          <welcomecard />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card">
      <div v-if="this.is_authorization === null" class="card-body">
        <div class="d-flex justify-content-center">
          authorization checking...
        </div>
      </div>
      <div v-if="this.is_authorization === 'N'" class="card-body">
        <div class="d-flex justify-content-center">
          You do not have authorization for this page
        </div>
      </div>
      <div v-if="this.is_authorization=== 'Y'" class="card-body">
        <div class="col-xl-12" v-if="this.eventDetails.is_enable_checkbib_function === 'N'">
          ยังไม่พร้อมใช้งาน กรุณาติดต่อผู้ดูแลระบบ
        </div>
        
        <div  class="col-xl-12" v-if="this.eventDetails.is_enable_checkbib_function === 'Y'">
          <div class="card">
            <h4 style="padding-left: 1em;padding-top: 1em;">รับเสื้อและบิบ</h4>
            <b style="padding-left: 2em;padding-top: 0em;">กิจกรรม: {{this.eventDetails.title }}</b>
            <div class="card-header">
              <div class="header-topxx" style="text-align: center; margin: 0 auto; width: fit-content;">
                <h5 class="m-0"></h5>
                <form class="form-inline">
                  <div class="form-group">
                    <select class="form-select" v-model="order_filter_type" style="font-size: 1.2rem; height: 3rem;">
                      <option value="mobile_no">หมายเลขโทรศัพท์</option>
                      <option value="fname">ชื่อผู้สมัคร</option>
                      <option value="id_card">เลขบัตรประชาชน</option>
                      <option value="order_uuid">เลขที่ใบสมัคร</option>
                      <option value="email">อีเมลล์</option>
                    </select>
                  </div>
                  &nbsp;
                  <div class="form-group">
                    <input
                      class="form-control"
                      v-model="order_filter_value"
                      type="text"
                      minlength="3" style="font-size: 1.2rem; height: 3rem;"
                      placeholder="คำค้นหา"
                    />
                  </div>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    @click.prevent="checkbib_search('')"
                    @submit.prevent="checkbib_search('')"
                    class="btn btn-primary btn-lg" style="font-size: 1.2rem; height: 3rem;"
                  >
                    <vue-feather type="search" size="18"> </vue-feather>
                    ค้นหา</button
                  >
                </form>
              </div>
            </div>


            

            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center">ใบสมัคร</th>
                    <!-- <th style="text-align: center">วันที่สมัคร</th> -->
                    <th style="text-align: center">ชื่อผู้สมัคร</th>
                    <th style="text-align: center">สกุลผู้สมัคร</th>
                    <th style="text-align: center">หมายเลขโทรศัพท์</th>
                    <th style="text-align: center">เพศ</th>
                    <th style="text-align: left">วันเดือนปีเกิด(อายุ)</th>
                    <th style="text-align: left">ประเภทการแข่งขัน</th>
                    <th style="text-align: left">รูปแบบการแข่งขัน</th>
                    <th style="text-align: left">เสื้อ</th>
                    <th style="text-align: left">หมายเลขบิบ</th>
                    <th style="text-align: left">สถานะ</th>
                    <th style="text-align: left">เช็คบิบ</th>
                  </tr>
                </thead>
                <tbody v-if="this.is_loading_last_registration === 'Y'">
                  <tr>
                    <td colspan="15">
                      <div class="d-flex justify-content-center">
                        <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.report_last_registration.length !== 0">
                  <tr v-for="item in this.report_last_registration" :key="item">
                    <td style="text-align: center"><a target="_blank" :href=" '/order/' + item.order_uuid"><u><b>{{ item.order_uuid }}</b></u></a></td>
                    <td style="text-align: left">{{ item.fname }} {{ item.lname }}</td>
                    <td style="text-align: left">{{ item.lname }}</td>
                    <td style="text-align: left">{{ item.mobile_no }}</td>
                    <td style="text-align: center">{{ item.sex }}</td>
                    <td style="text-align: center">{{ item.dob }}<br> (อายุ {{ item.age }} ปี)</td>
                    <td style="text-align: left">{{ item.run_type_name }}</td>
                    <td style="text-align: left">{{ item.run_model_name }}</td>
                    <td style="text-align: left">{{ item.t_shirt_name }}</td>
                    <td style="text-align: left"><b><u>{{ item.bib_no }}</u></b></td>
                    <td style="text-align: center" v-if="item.is_checkbib==='N'"><vue-feather stroke="red"  type="circle" size="18"> </vue-feather><br> รอดำเนินการ</td>
                    <td style="text-align: center" v-if="item.is_checkbib==='Y'"><vue-feather stroke="green"  type="check-circle" size="18"> </vue-feather><br> เรียบร้อย</td>
                    <td style="text-align: center"><vue-feather @click.prevent="checkbib(item.runner_id,item.fname,item.lname,item.run_type_name,item.t_shirt_name,item.event_key,item.order_uuid)" type="edit" style="cursor: pointer;" size="20"></vue-feather></td>
               </tr>
                </tbody>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 ><b>Export รายงาน รับเสื้อและบิบ</b></h5>
                
              </div>
            </div>
            <div class="header">
              <button style="margin: 2px;"  type="button" @click="load_order_excel_all()" class="btn btn-primary" >
              <vue-feather type="download" size="18" ></vue-feather> Download ข้อมูลผู้สมัครทั้งหมด
            </button>
            <button style="margin: 2px;" type="button" @click="load_order_excel_checkbib('Y')" class="btn btn-primary" >
              <vue-feather type="download" size="18" ></vue-feather> Download ข้อมูลผู้สมัคร ที่รับบิบแล้ว
            </button>
            <button style="margin: 2px;" type="button" @click="load_order_excel_checkbib('N')" class="btn btn-primary" >
              <vue-feather type="download" size="18" ></vue-feather> Download ข้อมูลผู้สมัคร ยังไม่รับบิบ
            </button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div> 
</template>

<script>
import welcomecard from "./user_welcomecard.vue";
import axios from "axios";
export default {
  components: {
    welcomecard,
  },
  data() {
    return {
      is_authorization: null,
      eventDetails: {},
      token: null,
      is_loading_runner_type: "N",
      report_runner_type: [],

      is_loading_runner_type_sex: "N",
      report_runner_type_sex: [],

      is_loading_tshrt: "N",
      report_tshirt: [],

      order_filter_type: "mobile_no",
      order_filter_value: "",
      is_loading_last_registration: "N",
      report_last_registration: [],

      is_loading_order_count: "N",
      report_order_count: [],

      is_loading_order_amt: "N",
      report_order_amt: [],

      is_loading_direct_notify: "N",
      report_direct_notify: [],

      is_loading_type_tshirt: "N",
      report_type_tshirt: [],
      

      is_loading_order_excel_all: "N",
      is_loading_order_excel_delivery: "N",
      is_loading_order_excel_self: "N"
    }
  },
  async mounted() {
    this.token = localStorage.getItem("_rr_token");
    if(this.token !== null){
      await this.authorization()
      if(this.is_authorization === "Y"){
        this.getEventDetail(this.$route.params.event_key);
        //this.clearLatestOrder();
        //this.load_runner_type();
        //this.load_runner_type_sex();
        //this.load_order_count();
        //this.load_order_amt();
      }
      

    }else{
      //alert("เซสชันหมดอายุ กรุณาเข้าสู่ระบบอีกครั้ง")
      this.$router.push("/auth/login");
    }
    
  },
  methods: {

    async checkbib(_runner_id,_fname,_lname,_run_type_name,_t_shirt_name,_event_key,_order_uuid){

      let msg = "กรุณาตรวจสอบข้อมูลก่อน ยืนยัน\n\nชื่อ: "+_fname +" "+_lname +"\nประเภทการแข่งขัน: " +_run_type_name +"\nขนาดเสื้อ: "+_t_shirt_name+"\n";
      if(confirm(msg)){

        const headers = {
          'Authorization': 'Bearer ' + this.token
        };
        
        const res = await axios.post("v1/organizer/checkbib-checking", {
          event_key: _event_key,
          order_uuid: _order_uuid,
          runner_id: _runner_id
        }, { headers });
        //console.log("res:", res)
        if (res.data.status === 'success') {
          this.checkbib_search('')
        } else {
          alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: "+res.data.message)
        }
        

      }
    },
    async checkbib_search(_filter) {

      if (this.order_filter_value.length == 0 || this.order_filter_value.length >= 3){
        this.is_loading_last_registration = "Y"
        this.report_last_registration = [];
        let data_req = {
          event_key: this.$route.params.event_key,
          order_filter_type: this.order_filter_type,
          order_filter_value: this.order_filter_value
        };
        }
        const headers = {
          'Authorization': 'Bearer ' + this.token
        };
        
        const res = await axios.post("v1/organizer/checkbib-search", {
          event_key: this.$route.params.event_key,
          order_filter_type: this.order_filter_type,
          order_filter_value: this.order_filter_value
        }, { headers });
        //console.log("res:", res)
        if (res.data.status === 'success') {
          this.report_last_registration = res.data.body
          this.is_loading_last_registration = "N"
        } else {

          alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: load_last_registration")
          this.is_loading_last_registration = "N"
        }
      }

      
    ,

    async delete_order(_uuid,_mobile_no){
      if(confirm("ยืนยันการ ลบข้อมูล \nสั่งซื้อ: "+_uuid+" \nหมายเลขโทรศัพท์ "+_mobile_no+"")){
          const data = {
            event_key: this.$route.params.event_key,
            uuid: _uuid
          };
          const headers = {
            'Authorization': 'Bearer ' + this.token
          };
          const res = await axios.post("v1/organizer/delete-order", data, { headers });
          if (res.data.status === 'success') {
            this.clearLatestOrder('');
          } else {

            alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: "+res.data.message	)
            this.is_loading_direct_notify = "N"
          }
      }
    },
    async authorization(_uuid,_mobile_no){
            const data = {
              event_key: this.$route.params.event_key
            };
            const headers = {
              'Authorization': 'Bearer ' + this.token
            };
            const res = await axios.post("v1/organizer/authorization", data, { headers });
            if (res.data.status === 'success') {
              this.report_direct_notify = res.data.body
              this.is_authorization = res.data.message.is_authorization
            } else {
              alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: "+res.data.message	)
            }
        },  
    async direct_notify(_uuid,_mobile_no,_id_card){

      //check direct-notify-checking
      const data_check = {
            event_key: this.$route.params.event_key,
            uuid: _uuid,
            id_card: _id_card
      };
      const headers = {
            'Authorization': 'Bearer ' + this.token
      };
      const res_check = await axios.post("v1/organizer/direct-notify-checking", data_check, { headers });
      if (res_check.data.status === 'success') {
        if(confirm("ยืนยันการรับชำระคำเงิน \nสั่งซื้อ: "+_uuid+" \nหมายเลขโทรศัพท์ "+_mobile_no+"")){
            const data = {
              event_key: this.$route.params.event_key,
              uuid: _uuid
            };
            
            const res = await axios.post("v1/organizer/direct-notify", data, { headers });
            if (res.data.status === 'success') {
              this.report_direct_notify = res.data.body
              this.is_loading_direct_notify = "N"
              this.clearLatestOrder('');
            } else {

              alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: "+res.data.message	)
              this.is_loading_direct_notify = "N"
            }
          }
      }else if(res_check.data.status === 'duplicate'){
        if(confirm(res_check.data.message)){
          if(confirm("ยืนยันการรับชำระคำเงิน \nสั่งซื้อ: "+_uuid+" \nหมายเลขโทรศัพท์ "+_mobile_no+"")){
            const data = {
              event_key: this.$route.params.event_key,
              uuid: _uuid
            };
            
            const res = await axios.post("v1/organizer/direct-notify", data, { headers });
            if (res.data.status === 'success') {
              this.report_direct_notify = res.data.body
              this.is_loading_direct_notify = "N"
              this.clearLatestOrder('');
            } else {

              alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: "+res.data.message	)
              this.is_loading_direct_notify = "N"
            }
          }
        }
      }else{
          alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: "+res_check.data.message	)
          this.is_loading_direct_notify = "N"
      }



      

      

    },
    async getEventDetail(event_key) {
      const res = await axios.get("/v1/events/" + event_key);
      //console.log("res.data", JSON.stringify(res.data))
      if (res.data.status === "success") {
        this.eventDetails = res.data;
        // //this.eventPackage = res.data.package;

        // return res.data;
      } else {
        //alert("พบข้อผิดพลาด! ไม่พบ url ของกิจกรรม");
        router.push({ path: "/404" });
      }
    },
    async load_runner_type() {
      this.is_loading_runner_type = "Y"
      this.report_runner_type = [];
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      const res = await axios.post("v1/organizer/report-runner-type", data, { headers });
      //console.log("res:", res)
      if (res.data.status === 'success') {
        this.report_runner_type = res.data.body
        this.is_loading_runner_type = "N"
      } else {

        alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: load_runner_type")
        this.is_loading_runner_type = "N"
      }
    },
    async load_runner_type_sex() {
      this.is_loading_runner_type_sex = "Y"
      this.report_runner_type_sex = [];
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      const res = await axios.post("v1/organizer/report-runner-type-sex", data, { headers });
      //console.log("res:", res)
      if (res.data.status === 'success') {
        this.report_runner_type_sex = res.data.body
        this.is_loading_runner_type_sex = "N"
      } else {

        alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: load_runner_type_sex")
        this.is_loading_runner_type_sex = "N"
      }
    },
    async load_tshirt() {
      this.is_loading_tshirt = "Y"
      this.report_tshirt = [];
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      const res = await axios.post("v1/organizer/report-runner-tshirt", data, { headers });
      console.log("res report-runner-tshirt:", res)
      if (res.data.status === 'success') {
        this.report_tshirt = res.data.body
        this.is_loading_tshirt = "N"
      } else {

        alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: load_tshirt")
        this.is_loading_tshirt = "N"
      }
    },
    async load_type_tshirt() {
      this.is_loading_type_tshirt = "Y"
      this.report_type_tshirt = [];
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      const res = await axios.post("v1/organizer/report-runner-type-tshirt", data, { headers });
      console.log("res report-runner-type_tshirt:", JSON.stringify(res))
      if (res.data.status === 'success') {
        this.report_type_tshirt = res.data.body
        this.is_loading_type_tshirt = "N"
      } else {

        alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: load_tshirt")
        this.is_loading_type_tshirt = "N"
      }
    },
    async clearLatestOrder(_filter) {
      this.order_filter_value = "";
      
      this.load_last_registration('');
    },


    async load_last_registration(_filter) {

      if (this.order_filter_value.length == 0 || this.order_filter_value.length >= 3){
        this.is_loading_last_registration = "Y"
        this.report_last_registration = [];
        let data = {
          event_key: this.$route.params.event_key,
          order_filter_type: this.order_filter_type,
          order_filter_value: this.order_filter_value
        };
        if(_filter === 'INITIAL_HAVE_SLIP'){
          data = {
          event_key: this.$route.params.event_key,
          order_filter_type: "INITIAL_HAVE_SLIP",
          order_filter_value: "spacial"
        };
        }
        const headers = {
          'Authorization': 'Bearer ' + this.token
        };
        
        const res = await axios.post("v1/organizer/report-order-latest", data, { headers });
        //console.log("res:", res)
        if (res.data.status === 'success') {
          this.report_last_registration = res.data.body
          this.is_loading_last_registration = "N"
        } else {

          alert("พบข้อผิดพลาด[กรุณาแจ้งผู้ดูแลระบบ]: load_last_registration")
          this.is_loading_last_registration = "N"
        }
      }

      
    },
    async load_order_count() {
      this.is_loading_order_count = "Y"
      this.report_order_count = [];
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      const res = await axios.post("v1/organizer/report-order-count", data, { headers });
      //console.log("res:", res)
      if (res.data.status === 'success') {
        this.report_order_count = res.data.body
        this.is_loading_order_count = "N"
      } else {

        alert("พบข้อผิดพลาด")
        this.is_loading_order_count = "N"
      }
    },
    async load_order_amt() {
      this.is_loading_order_amt = "Y"
      this.report_order_amt = [];
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      const res = await axios.post("v1/organizer/report-order-amt", data, { headers });
      //console.log("res:", res)
      if (res.data.status === 'success') {
        this.report_order_amt = res.data.body
        this.is_loading_order_amt = "N"
      } else {

        alert("พบข้อผิดพลาด")
        this.is_loading_order_amt = "N"
      }
    },
    
    async load_order_excel_all(){

      this.is_loading_order_excel_all = "Y"
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      
      await axios({
          url: 'v1/organizer/report-order-excel-all',
          method: 'POST',
          data: data,
          responseType: 'blob',
          headers: headers
      }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          const dt = new Date();
          const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
          let txt_file = dt.getFullYear()+padL(dt.getMonth()+1)+padL(dt.getDate())+padL(dt.getHours())+padL(dt.getMinutes())+padL(dt.getSeconds())
         

          const excel_report_file_name = "[REPORT-ALL]"+this.$route.params.event_key+"-"+txt_file+".xlsx"
          link.setAttribute('download', excel_report_file_name); 
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.is_loading_order_excel_all = "N"
      });
      this.is_loading_order_excel_all = "N"
    },

    async load_order_excel_checkbib(_ischeckbib){

      this.is_loading_order_excel_delivery = "Y"
      const data = {
        event_key: this.$route.params.event_key,
        is_checkbib: _ischeckbib

      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };

      await axios({
          url: 'v1/organizer/report-runner-excel-checkbib', 
          method: 'POST',
          data: data,
          responseType: 'blob',
          headers: headers
      }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          const dt = new Date();
          const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
          let txt_file = dt.getFullYear()+padL(dt.getMonth()+1)+padL(dt.getDate())+padL(dt.getHours())+padL(dt.getMinutes())+padL(dt.getSeconds())
        
          let excel_report_file_name = "[REPORT-CHECKBIB]"+this.$route.params.event_key+"-"+txt_file+".xlsx"
          if(_ischeckbib === 'Y'){
            excel_report_file_name = "[REPORT-CHECKBIB-DONE]"+this.$route.params.event_key+"-"+txt_file+".xlsx"
          }
          
          link.setAttribute('download', excel_report_file_name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          this.is_loading_order_excel_delivery = "N"
      });
      this.is_loading_order_excel_delivery = "N"
    },
    async load_order_excel_self(){

      this.is_loading_order_excel_self = "Y"
      const data = {
        event_key: this.$route.params.event_key
      };
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };

      await axios({
          url: 'v1/organizer/report-order-excel-self',
          method: 'POST',
          data: data,
          responseType: 'blob', 
          headers: headers
      }).then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          const dt = new Date();
          const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
          let txt_file = dt.getFullYear()+padL(dt.getMonth()+1)+padL(dt.getDate())+padL(dt.getHours())+padL(dt.getMinutes())+padL(dt.getSeconds())
        

          const excel_report_file_name = "[REPORT-SELF]"+this.$route.params.event_key+"-"+txt_file+".xlsx"
          link.setAttribute('download', excel_report_file_name);
          link.click();
          URL.revokeObjectURL(href);
          this.is_loading_order_excel_self = "N"
      });
      this.is_loading_order_excel_self = "N"
    }
  }
};
</script>
